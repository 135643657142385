import {useMemo} from "react";
import {ITenantUpdating, MultilineTextElement, TextFieldElement} from "@ib-tehnologije/react-widgets";
import {
    CompanyUpdateDtoInput,
    UpdateCompanyMutation,
    useUpdateCompanyMutation
} from "../graphql-types";
import {ActualCompanyReturnDto} from "../ReturnTypes/ActualCompanyReturnDto";

export const useCompanyUpdating = (): ITenantUpdating<UpdateCompanyMutation, ActualCompanyReturnDto, CompanyUpdateDtoInput, "data"> => {
    const [updateCompany] = useUpdateCompanyMutation();

    return useMemo<ITenantUpdating<UpdateCompanyMutation, ActualCompanyReturnDto, CompanyUpdateDtoInput, "data">>(() => ({
        modelKey: "data",
        formDefinition: {
            name: {
                type: new TextFieldElement({}),
                translationKey: "company-name"
            },
            companyEmail: {
                type: new TextFieldElement({})
            },
            description: {
                type: new MultilineTextElement({rows: 3}),
            },
            taxId: {
                type: new TextFieldElement({})
            },
            externalId: {
                type: new TextFieldElement({})
            }
        },
        selectionToBaseFormData: (selection) => ({
            id: selection?.id,
            name: selection?.name,
            taxId: selection?.taxId,
            externalId: selection?.externalId,
            companyEmail: selection?.companyEmail,
        }),
        formDataToDto: (formData) => ({
            name: formData.name,
            id: formData.id,
            taxId: formData.taxId,
            externalId: formData.externalId,
            companyEmail: formData.companyEmail,
        }),
        fn: updateCompany
    }), []);
}