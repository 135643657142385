import {useMemo} from "react";
import {ITenantCreating, MultilineTextElement, TextFieldElement} from "@ib-tehnologije/react-widgets";
import {
    CompanyCreateDtoInput,
    CreateCompanyMutation,
    useCreateCompanyMutation
} from "../graphql-types";
import {ActualCompanyReturnDto} from "../ReturnTypes/ActualCompanyReturnDto";

export const useCompanyCreating = (): ITenantCreating<CreateCompanyMutation, ActualCompanyReturnDto, CompanyCreateDtoInput, "data"> => {
    const [createCompany] = useCreateCompanyMutation();

    return useMemo<ITenantCreating<CreateCompanyMutation, ActualCompanyReturnDto, CompanyCreateDtoInput, "data">>(() => ({
        modelKey: "data",
        formDefinition: {
            name: {
                type: new TextFieldElement({}),
                translationKey: "company-name"
            },
            companyEmail: {
                type: new TextFieldElement({})
            },
            description: {
                type: new MultilineTextElement({rows: 3}),
            },
            taxId: {
                type: new TextFieldElement({})
            },
            externalId: {
                type: new TextFieldElement({})
            }
        },
        baseFormData: {},
        formDataToDto: (formData) => ({
            name: formData.name,
            taxId: formData.taxId,
            description: formData.description,
            externalId: formData.externalId,
            companyEmail: formData.companyEmail
        }),
        fn: createCompany
    }), []);
}